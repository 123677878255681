import React from "react";
import "./prosAndCons.css";

const ProsAndCons = ({ pros_list, cons_list, pros_header, cons_header }) => {
  return (
    <div className="pros_cons_wrapper">
      <div className="pros">
        <h4>{pros_header}</h4>
        <ul>
          {pros_list.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </ul>
      </div>
      <div className="cons">
        <h4>{cons_header}</h4>
        <ul>
          {cons_list.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProsAndCons;
