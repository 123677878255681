/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Img from "gatsby-image"

const Image = ({ children, rowOne, rowTwo, fixed, alt, title }) => {
  return (
    <figure>
      {children}
      <Img fixed={fixed} alt={alt} title={title} />
      <figcaption>
        {rowOne}
        <br />
        {rowTwo}
      </figcaption>
    </figure>
  )
}

export default Image
