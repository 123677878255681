// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-base-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-base/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-base-src-pages-404-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fallgrindar-mdx": () => import("./../../../src/pages/fallgrindar.mdx" /* webpackChunkName: "component---src-pages-fallgrindar-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-rullbara-mdx": () => import("./../../../src/pages/rullbara.mdx" /* webpackChunkName: "component---src-pages-rullbara-mdx" */),
  "component---src-pages-sakerhetstips-mdx": () => import("./../../../src/pages/sakerhetstips.mdx" /* webpackChunkName: "component---src-pages-sakerhetstips-mdx" */),
  "component---src-pages-spanngrindar-mdx": () => import("./../../../src/pages/spanngrindar.mdx" /* webpackChunkName: "component---src-pages-spanngrindar-mdx" */),
  "component---src-pages-valj-ratt-mdx": () => import("./../../../src/pages/valj-ratt.mdx" /* webpackChunkName: "component---src-pages-valj-ratt-mdx" */),
  "component---src-pages-vanliga-fragor-mdx": () => import("./../../../src/pages/vanliga-fragor.mdx" /* webpackChunkName: "component---src-pages-vanliga-fragor-mdx" */),
  "component---src-pages-vanliga-misstag-mdx": () => import("./../../../src/pages/vanliga-misstag.mdx" /* webpackChunkName: "component---src-pages-vanliga-misstag-mdx" */),
  "component---src-pages-vikbara-mdx": () => import("./../../../src/pages/vikbara.mdx" /* webpackChunkName: "component---src-pages-vikbara-mdx" */)
}

